@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Gilroy Extra Bold";
  font-weight: bold;
  src: url("https://s3.us-east-2.amazonaws.com/cloudsitedsolutions.backend/fonts/Gilroy-ExtraBold.otf")
    format("truetype");
}

@font-face {
  font-family: "Gilroy Light";
  font-style: italic;
  font-weight: bold;
  src: url("https://s3.us-east-2.amazonaws.com/cloudsitedsolutions.backend/fonts/Gilroy-Light.otf")
    format("truetype");
}
